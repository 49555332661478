<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
               <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Videos de Ajuda
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                </v-toolbar>
                <v-card elevation="2" class="py-4">
                    <v-card-text :style="`height: ${$vuetify.breakpoint.height - 200}px; overflow-x: auto;`">
                        <v-expansion-panels class="pa-2" focusable>
                            <v-expansion-panel v-show="video.visible" v-for="(video, i) in videos" :key="i">
                                <v-expansion-panel-header>
                                    <b>
                                        <v-icon class="pb-1">mdi-play</v-icon>
                                        {{ video.title }}
                                    </b>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pt-4">
                                    <iframe
                                        class="video-container"
                                        :src="video.url"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    />
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Ajuda',

    components: { BaseContainer },

    data: () => ({
        search: '',
        loading: true,
        videos: [],
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        search(v) {
            this.filter(v);
        },
    },

    methods: {
        consultar() {
            this.loading = true;
            this.videos = [];
            this.$http.get('videos-de-ajuda').then(resp => {
                this.videos = resp.data.map(e => {
                    e.visible = true;
                    return e;
                });

                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
                this.loading = false;
            });
        },

        filter(term = '') {
            this.videos.forEach(e => {
                console.log(term, e.title)
                e.visible = term
                    ? e.title.toLocaleLowerCase().includes(term.toLocaleLowerCase())
                    : true;
            });
        },
    },
}
</script>

<style scoped>
.video-container {
    aspect-ratio: 16 / 9;
    width: 100%;
    max-width: 1080px;
}
</style>
